import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h3: "h3",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Card} = _components;
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "battle-royale",
      children: _jsx(_components.a, {
        href: "#battle-royale",
        children: "Battle Royale"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx("video", {
        preload: "metadata",
        controlsList: "nodownload",
        muted: true,
        controls: true,
        children: _jsx("source", {
          src: `${appURLs.CDN_VIDEOS}/fortnite/movement-speed.mp4`,
          type: "video/mp4"
        })
      }), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Movement improvements"
        }), "\nMovement speed has been increased when crouching, and running. We also reduced camera movement and adjusted the change in view while sprinting to encourage a smoother experience. Plus, we updated the movement animations to better match the adjustments in speed."]
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "To help you get adjusted to these updates, we’ve reset everyone’s “Custom Diagonal Movement” settings, so please log in and reconfigure your settings to what feels best."
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Medallions readability"
        }), "\nSociety Medallion icons now stand out more on the map and should be easier to spot at a glance. We also reduced the visual noise present in the minimap when several Medallion zones are near one another, especially in the later Storm circles."]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Night visibility"
        }), "\nWe’ve brightened certain areas of the map where it was a little too dark during night."]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Storm visibility"
        }), "\nThe visibility of the Storm’s edge has been improved so it’s more apparent how close it is, as we heard from the community it could be difficult to see."]
      }), _jsx("br", {})]
    }), "\n", _jsx(_components.h3, {
      id: "balance-changes",
      children: _jsx(_components.a, {
        href: "#balance-changes",
        children: "Balance Changes"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "Last week, we also released a lot of balance changes to Battle Royale. Here’s a recap!"
      }), _jsx("br", {}), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Flowberries now grant more Shield."
        }), "\n", _jsx(_components.li, {
          children: "Increased damage for the Ranger Pistol, Shield Breaker EMP, and Ballistic Shield's pistol."
        }), "\n", _jsx(_components.li, {
          children: "Snowball Launcher mag size reduced."
        }), "\n", _jsx(_components.li, {
          children: "Thunder Burst SMG mag size increased."
        }), "\n", _jsx(_components.li, {
          children: "Reduced the drop rate of the Reaper Sniper Rifle."
        }), "\n", _jsx(_components.li, {
          children: "Striker AR deals less damage."
        }), "\n", _jsx(_components.li, {
          children: "The circle indicating a Society Medallion holder is smaller."
        }), "\n", _jsx(_components.li, {
          children: "Society Medallions now have a brief delay before Shield regen begins. They also have slightly reduced regen rates and no longer refill 100% Shield (unless you have all five)."
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "lego-fortnite",
      children: _jsx(_components.a, {
        href: "#lego-fortnite",
        children: "Lego Fortnite"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx("h5", {
        children: "Durability"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "The durability of non-Rare/non-Epic tools and weapons has increased by 30%. For Rare/Epic tools and weapons, the durability has increased by 60%!"
        }), "\n"]
      }), _jsx("br", {}), _jsx("h5", {
        children: "Damage"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Reduced damage of the Brute’s Poison area for all three Brutes."
        }), "\n", _jsx(_components.li, {
          children: "Reduced damage of the Frost Scorpion explosion."
        }), "\n"]
      }), _jsx("br", {}), _jsx("h5", {
        children: "Critter Changes"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Sand Scorpions, Scorpions found in caves, regular Scorpions, Crabs on the shoreline, Sand Spiders, and Alpine Frost Spiders are slightly easier to defeat."
        }), "\n", _jsx(_components.li, {
          children: "Rams now give Heavy Wool."
        }), "\n"]
      }), _jsx("br", {}), _jsx("h5", {
        children: "Other Tweaks and Improvements"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Grapplers now hold 30 charges."
        }), "\n", _jsx(_components.li, {
          children: "Spiders won’t jumpscare you as often when turning over rocks."
        }), "\n"]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "rocket-racing",
      children: _jsx(_components.a, {
        href: "#rocket-racing",
        children: "Rocket Racing"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Long queue times"
        }), "\nWe heard feedback that high-ranked racers have been experiencing long queue times in Rocket Racing, and our team is working on incremental improvements to help you get into your races faster. We’ll continue to monitor queue times and make adjustments if necessary."]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Car collisions"
        }), "\nCar-on-car collisions have been a major topic among players, especially when a bump from behind spins your car 180 degrees. Today's update will make those collisions a bit less punishing, and should help keep your car facing the finish line. We’ll continue to work on collision improvements for future updates too."]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Jackie outfit quest"
        }), "\nThe Reach Gold rank in Rocket Racing Quest to unlock the Jackie Outfit was rewarding players for reaching Gold II instead of Gold I. We’ve patched this so that racers who have hit Gold I now just need to complete one full race to complete the Quest and receive the Jackie Outfit."]
      })]
    }), "\n", _jsx(_components.h3, {
      id: "fortnite-festival",
      children: _jsx(_components.a, {
        href: "#fortnite-festival",
        children: "Fortnite Festival"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Missing song library"
        }), "\nWe fixed an error that caused players to see an empty song library in the Main Stage after suspending Fortnite on their device. This should also improve the pauses some players were encountering before a song starts."]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Fall damage disabled"
        }), "\nFall damage has been disabled in the Main Stage’s Backstage. Jump off all the balconies you’d like, rockstars!"]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Restart song"
        }), "\nWe’ve disabled the “Restart Song” functionality (available to Solo players) temporarily while we fix an error that was causing the game to become unresponsive."]
      }), _jsx("br", {}), _jsxs(_components.p, {
        children: [_jsx("h4", {
          children: "Incorrect posting to leaderboards"
        }), "\nWe fixed an issue where some scores may have been posted to the wrong leaderboard. We will continue to monitor (and potentially audit) leaderboards moving forward to ensure their accuracy."]
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
