import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {appURLs} from "@/app/constants.mjs";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h3: "h3",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Card} = _components;
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "whats-new",
      children: _jsx(_components.a, {
        href: "#whats-new",
        children: "What’s New"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx("p", {
        children: _jsx("strong", {
          children: "The Lamborghini HURACÁN STO bundle accelerates into Fortnite"
        })
      }), _jsx(_components.p, {
        children: "Feel the thrill of driving an exotic super-sports car in Rocket Racing or the Battle Royale Island — the Lamborghini Huracán STO Bundle is in the Fortnite Shop now and includes the Lamborghini Huracán STO Car Body, Decal, and Wheels (v1 and v2)."
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "If you’re already the proud owner of these sleek items in Rocket League, they’re waiting in your Fortnite Locker thanks to cross-game ownership. Just make sure you’re playing Fortnite with the same Epic Games account you use to play Rocket League!"
      }), _jsx("br", {}), _jsx("img", {
        src: `${appURLs.CDN}/fortnite/articles/huracan-sto-1.webp`
      }), _jsx("br", {}), _jsx("p", {
        children: _jsx("strong", {
          children: "Pure performance delivered with maximum style"
        })
      }), _jsx(_components.p, {
        children: "Lamborghini Huracán STO is built for Rocket Racing-level performance without compromising on style. This stunning supercar has a specially designed body component with a carbon fiber finish, complete with its distinctive V10 engine audio."
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Simply put: no matter how intense things get when you take it for a spin, you’ll at the very least be in automotive excellence."
      }), _jsx("br", {}), _jsx("img", {
        src: `${appURLs.CDN}/fortnite/articles/huracan-sto-2.webp`
      }), _jsx("br", {}), _jsx(_components.p, {
        children: "Available now in the Fortnite Shop, the Lamborghini Huracán STO Bundle includes the following items:"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Lamborghini Huracán STO Car Body"
        }), "\n", _jsx(_components.li, {
          children: "Lamborghini Huracán STO Decal (Has a v1 and v2 in Fortnite)"
        }), "\n", _jsx(_components.li, {
          children: "Lamborghini Huracán STO v1 Wheels"
        }), "\n", _jsx(_components.li, {
          children: "Lamborghini Huracán STO v2 Wheels"
        }), "\n"]
      }), _jsx(_components.p, {
        children: "Tailor-made, the Lamborghini Huracán STO Decal and Wheels can only be equipped with the Lamborghini Huracán STO Car Body. Take off with a fine-tuned loadout."
      }), _jsx(_components.p, {
        children: "This one-of-a-kind supercar awaits. If you don’t own the four items in Rocket League, you’ll own them in Rocket League after purchasing the bundle in Fortnite.Car Bodies, Decals, and Wheels grant no competitive advantage."
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
